const gameList =[{
  date:"October 21st",
  link:"https://store.steampowered.com/app/1879950/Psychroma/",
  name:"Psychroma",
  type:"game",
},
{
  date:"October 28th",
  link:"https://store.steampowered.com/app/1262350/SIGNALIS/",
  name:"Signalis",
  type:"game",
},
{
  date:"November 4th",
  link:"https://store.steampowered.com/sale/nextfest",
  name:"Steam Next Fest",
  type:"game",
},
{
  date:"November 11th",
  link:"https://store.steampowered.com/app/3048140/Daemonologie/",
  name:"Daemonologie",
  type:"game",
},
{
  date:"November 18th",
  link:"https://store.steampowered.com/app/1989270/Slay_the_Princess__The_Pristine_Cut/",
  name:"Slay the Princess",
  type:"game",
},
{
  date:"November 25th",
  link:"https://www.google.com/search?client=firefox-b-1-d&sca_esv=4a40c1ce277ae477&sxsrf=ADLYWII4rusjTAJPKfsnaFJW__DSjZRTtQ:1731795389772&q=touching+grass+and+eating+turkey&udm=2&fbs=AEQNm0AbzhUJjXv6jRup8eVc0BvPyH5PazCaW205cG-Bd0in0dvSnU368ct8hcGzY9zRCgahXUvXYtux5SPCz-VD8JjCRRQ8LRqTDdEUYpRLpCO7i4Abpfgr5RKfHAgc3Zq8pbKkp0mi68K3CXDFL7KsrYadrVQgVTdiihKp3Ia169rEPCwfPwJXLr9DBt3ZwxM0XlbirQsyiZwWgE0_ock6dT6lmfSWVA&sa=X&ved=2ahUKEwjeu5_I8OGJAxUdweYEHVOQMygQtKgLegQIHRAB&biw=1175&bih=1245&dpr=1",
  name:"No Episode!",
  type:"game",
},
{
  date:"December 2nd",
  link:"https://store.steampowered.com/app/269030/The_Yawhg/",
  name:"The Yawhg",
  type:"game",
},
{
  date:"December 9th",
  link:"https://store.steampowered.com/app/2475490/Mouthwashing/",
  name:"Mouthwashing",
  type:"game",
},
{
  date:"December 16th",
  link:"https://store.steampowered.com/app/1959390/Fear_the_Spotlight/",
  name:"Fear the Spotlight",
  type:"game",
},
{
  date:"December 23rd",
  link:"https://www.etsy.com/listing/1675349779/something-stupid-print?gpla=1&gao=1&",
  name:"????",
  type:"game",
},
{
  date:"December 30th",
  link:"https://www.google.com/search?client=firefox-b-1-d&sca_esv=4a40c1ce277ae477&sxsrf=ADLYWIIM5PowofvwGiF2Iw6IgYTx5NuerQ:1731795736664&q=zipper+of+the+year&udm=2&fbs=AEQNm0A-5VTqs5rweptgTqb6m-Eb3TvVcv4l7eCyod9RtZW98xhblUVZPzVv1Bs6vKE1-BNpBZmVjXeMAEihQ07BrNjgBSVvptKTZ7uCSG2sfXEVsGKrhcWP4sObo66efMvpnraWqdYJUcDf3Tvbeum96jL-99eQ8IuThaqZpMfgFCFtZHxNWVELfpDyyiNO3kvEzYpeGIlIj0NgZ3gLHGHG3z82CX2eYg&sa=X&ved=2ahUKEwidhdTt8eGJAxV4OjQIHWhHMyYQtKgLegQIDRAB&biw=1381&bih=1245&dpr=1",
  name:"ZOTY",
  type:"game",
},
{
  date:"January 6th",
  link:"https://store.steampowered.com/app/2678990/Minami_Lane/",
  name:"Minami Lane",
  type:"game",
},
{
  date:"January 13th",
  link:"https://store.steampowered.com/app/2858430/Lake_Minnewaska/",
  name:"Lake Minnewaska",
  type:"game",
},
{
  date:"January 20th",
  link:"https://store.steampowered.com/app/1585220/Melatonin/",
  name:"Melatonin",
  type:"game",
},
{
  date:"January 27th",
  link:"https://orteil.dashnet.org/nested",
  name:"Nested",
  type:"game",
},
{
  date:"February 3rd",
  link:"https://cxmi.itch.io/worm-girlfriend",
  name:"worm girlfriend dating simulator",
  type:"game",
},
{
  date:"February 10th",
  link:"https://bannerlessgames.itch.io/void-1680-am",
  name:"Void 1680 AM",
  type:"ttrpg",
},
{
  date:"February 17th",
  link:"https://store.steampowered.com/app/702670/Donut_County/",
  name:"Donut County",
  type:"game",
},
{
  date:"February 24th",
  link:"https://store.steampowered.com/app/1172800/Perfect_Tides/",
  name:"Perfect Tides",
  type:"game",
}]



export default gameList

